import { template as template_f8c10a8e4b454e0fba6370fb6ea26b6f } from "@ember/template-compiler";
const FKControlMenuContainer = template_f8c10a8e4b454e0fba6370fb6ea26b6f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
