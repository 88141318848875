import { template as template_686d9fde59a9448080baca537b0dca9a } from "@ember/template-compiler";
const FKText = template_686d9fde59a9448080baca537b0dca9a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
