import { template as template_40d1b20b5d8f4790845444f0e48b8d07 } from "@ember/template-compiler";
const FKLabel = template_40d1b20b5d8f4790845444f0e48b8d07(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
